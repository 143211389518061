// src/components/Clients.js
import React from 'react';
import styled from 'styled-components';
import coopergraos from '../assets/coopergraos.png';
import mariaBonita from '../assets/maria-bonita.png';
import ervaDoce from '../assets/erva-doce.png';
import borgesLima from '../assets/borges-lima.png';

const ClientsSection = styled.section`
  background: #f5f5f5;
  padding: 50px 0;
  text-align: center;

  h2 {
    margin-bottom: 30px;
    font-size: 25px;
    color: #333;
  }
`;

const ClientsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  align-items: center;
  justify-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const ClientLogo = styled.img`
  max-width: 50%;
  height: auto;
  filter: grayscale(100%);
  transition: filter 0.3s;

  &:hover {
    filter: grayscale(0%);
  }
`;

const Clients = () => (
  <ClientsSection>
    <h2>Alguns Clientes</h2>
    <ClientsGrid>
      <ClientLogo src={coopergraos} alt="Coopergrãos" />
      <ClientLogo src={mariaBonita} alt="Maria Bonita" />
      <ClientLogo src={ervaDoce} alt="Erva Doce Lingerie" />
      <ClientLogo src={borgesLima} alt="Borges Lima" />
    </ClientsGrid>
  </ClientsSection>
);

export default Clients;
