// src/components/Features.js
import React from 'react';
import styled from 'styled-components';
import { FaCashRegister, FaBoxes, FaFileInvoice } from 'react-icons/fa';

const FeaturesContainer = styled.section`
  padding: 100px 50px;
  background: #1f1f1f;
  color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background: #2d2d2d;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;

  h3 {
    margin: 10px 0;
  }

  p {
    font-size: 16px;
  }
`;

const Features = () => (
  <FeaturesContainer>
    <FeatureCard>
      <FaFileInvoice size={40} color="#34d399" />
      <h3>Emissão de Nota Fiscal</h3>
      <p>Emita notas fiscais rapidamente e automatize seu processo financeiro.</p>
    </FeatureCard>
    <FeatureCard>
      <FaBoxes size={40} color="#34d399" />
      <h3>Gestão de Estoque</h3>
      <p>Controle o estoque em tempo real e evite falta de produtos.</p>
    </FeatureCard>
    <FeatureCard>
      <FaCashRegister size={40} color="#34d399" />
      <h3>Frente de Caixa</h3>
      <p>Gerencie vendas com agilidade e facilidade.</p>
    </FeatureCard>
  </FeaturesContainer>
);

export default Features;
