// src/components/CTA.js
import React from 'react';
import styled from 'styled-components';

const CTASection = styled.section`
  background: #2575FC;
  padding: 75px;
  text-align: center;
  color: white;

  h2 {
    margin-bottom: 20px;
  }
`;

const CTAButton = styled.a`
  background: #34d399;
  color: white;
  padding: 15px 50px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
`;

const CTA = () => (
  <CTASection>
    <h2>Pronto para descomplicar sua gestão?</h2>
    <CTAButton href="#">Experimente Agora</CTAButton>
  </CTASection>
);

export default CTA;
