// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background: #1f1f1f;
  color: white;
  padding: 40px 0;
  text-align: center;
  position: relative; /* Necessário para posicionar o botão flutuante */
`;

const FooterText = styled.p`
  margin: 10px 0;
  font-size: 14px;
`;

const SocialIcons = styled.div`
  margin: 20px 0;

  a {
    color: white;
    margin: 0 10px;
    font-size: 24px;
    transition: color 0.3s;

    &:hover {
      color: #34d399;
    }
  }
`;

const WhatsappButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const Footer = () => (
  <>
    <FooterContainer>
      <SocialIcons>
        <a href="https://facebook.com/athixbr" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://instagram.com/athixbr" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      
      </SocialIcons>
      <FooterText>Sistema de gestão online para micro e pequenos empresários</FooterText>
      <FooterText>©2024, Todos Direitos Reservados</FooterText>
    </FooterContainer>

    <WhatsappButton
      href="https://wa.me/5566999960422" // Troque pelo seu número de WhatsApp
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp />
    </WhatsappButton>
  </>
);

export default Footer;
