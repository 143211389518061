import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import CTA from './components/CTA';
import Clients from './components/Clients';
import Footer from './components/Footer';
import { Element } from 'react-scroll';

function App() {
  return (
    <>
      <Header />
      <Element name="hero">
        <Hero />
      </Element>
      <Element name="features">
        <Features />
      </Element>
      <CTA />
      <Clients />
      <Footer />
    </>
  );
}

export default App;
